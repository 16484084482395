import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { AuthData } from '../models/auth/auth-data.model';
import { UserData } from '../models/auth/user-data.model';
import { HttpService } from '../services/http.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(private http: HttpService, private router: Router) {}

  public login(authData: AuthData): Observable<UserData> {
    const URL = '/saas/v1.0/saas/login';
    // allowed user roles
    const accessRoleList = ['admin'];

    return this.http.post(URL, authData).pipe(
      map((r: UserData) => {
        if (r.succeeded && accessRoleList.includes(r.role)) {
          return r;
        } else if (r.role) {
          throw { clientStatus: 'User doesn`t have access' };
        } else {
          throw { clientStatus: 'Auth failed' };
        }
      }),
      catchError((err: HttpErrorResponse) => {
        return throwError(err);
      }),
    );
  }

  public setAuthData(data: UserData) {
    localStorage.setItem('auth', JSON.stringify(data));
  }

  public getAuthData(): UserData {
    return JSON.parse(localStorage.getItem('auth'));
  }

  public logout() {
    localStorage.clear();
    this.router.navigate(['/login']);
  }

  public checkCookie(): Observable<any> {
    return this.http.get('/saas/v1.0/saas/checkcookie').pipe(
      map((r) => r),
      catchError((err: HttpErrorResponse) => {
        this.logout();
        throw throwError(err);
      }),
    );
  }
}
