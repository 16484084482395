/**
 * Сервис для взаимодействия с микросервисом Profilum.Services.Gateway
 */
import { HttpService } from './http.service';
import { inject, Injectable } from '@angular/core';
import { OperationResultInfo } from '../models/operationresultinfo.class';
import { Region } from '../models/region.class';
import { Municipality } from '../models/municipality.class';
import { CopingTest, ScreeningTest } from '../models/screeningtest.class';
import { RegionTestInfo } from '../models/regiontestinfo.class';
import { ScreeningTestBaseInfo } from '../models/screeningtestbaseinfo.class';
import { Aggregate } from '../models/aggregate.class';
import { AdditionalText } from '../models/additionaltext.class';
import { Object } from '../models/object.class';
import { FactorsList } from '../models/factors/factorslist.class';
import { UploadFactor } from '../models/factors/uploadfactor.class';
import { Minmax } from '../models/minmaxes/minmax.class';
import { Norm } from '../models/norms/norm.class';
import { RebuildNorms } from '../models/rebuildnorms.class';
import { TerritoryObject } from '../models/territoryobject.class';
import { ObjectTypes } from '../models/enums/objecttypes.enum';
import { map } from 'rxjs/operators';
import { Field } from '../models/catalog_models/field.class';
import { Observable } from 'rxjs';
import { HttpResponse } from '@angular/common/http';

@Injectable()
export class GatewayService {
  private http: HttpService = inject(HttpService);

  private _testsList: ScreeningTestBaseInfo[];

  public get getLocalTestsList(): ScreeningTestBaseInfo[] {
    return structuredClone(this._testsList);
  }

  // Выгрузить результаты тестирования за определенные даты
  async getTestPassesStatistics(screeningTestId: string, startDate?: string, endDate?: string): Promise<HttpResponse<Blob>> {
    const params = {
      screeningTestId: screeningTestId,
      afterDate: startDate,
      beforeDate: endDate,
    };

    return await this.http.postFileSave('/api/v1.0/player/tests/gettestpassesstatistics', params).toPromise();
  }

  //#region Utils
  // Обновить объекты профессий для региона
  async synchronizeProfessionObjects(regionId: string): Promise<OperationResultInfo> {
    const result = await this.http.get('/api/v1.0/utils/synchronizeprofessionobjects?regionId=' + regionId).toPromise();
    return result as OperationResultInfo;
  }
  //#endregion

  //#region Catalog
  async getFields(): Promise<Field[]> {
    const fields = await this.http.get('/api/v1.0/catalog/search/fields').toPromise();
    return fields.result as Field[];
  }

  async getRegions(): Promise<Region[]> {
    const regions = await this.http
      .get('/api/v1.0/catalog/regions/all')
      .pipe(map(r => r.regions))
      .toPromise();
    return regions as Region[];
  }

  async getMunicipalities(regionId: string): Promise<Municipality[]> {
    const municipalities = await this.http.get('/api/v1.0/catalog/municipalities/getbyregion?regionId=' + regionId).toPromise();
    return municipalities as Municipality[];
  }

  async getMunicipality(regionId: string): Promise<Municipality> {
    const municipality = await this.http.get('/api/v1.0/catalog/municipalities/one?municipalityId=' + regionId).toPromise();
    return municipality as Municipality;
  }
  //#endregion

  //#region Player
  // Получаем список тестов
  async getTestsList(): Promise<ScreeningTestBaseInfo[]> {
    const testsList = await this.http.get('/api/v1.0/player/tests/list').toPromise();
    this._testsList = testsList;
    return testsList as ScreeningTestBaseInfo[];
  }

  // Получаем структура теста по id
  async getTestData(screeningTestId): Promise<ScreeningTest> {
    const testData = await this.http.get('/api/v1.0/player/tests?screeningTestId=' + screeningTestId).toPromise();
    return testData as ScreeningTest;
  }

  // Создать новый скрининг тест
  async createTest(screeningtest: ScreeningTest): Promise<string> {
    const response = await this.http.post('/api/v1.0/player/tests', screeningtest).toPromise();
    return response as string;
  }

  // Изменение скрининг теста
  async updateTest(screeningtest: ScreeningTest): Promise<any> {
    await this.http.put('/api/v1.0/player/tests', screeningtest).toPromise();
  }

  // Удалить тест
  async deleteTest(testId: string): Promise<any> {
    await this.http.delete('/api/v1.0/player/tests?screeningTestId=' + testId).toPromise();
  }

  // Получить список привязок тестов по всем регионам
  async getRegionTestInfoList(): Promise<RegionTestInfo[]> {
    const regionsList = await this.http.get('/api/v1.0/player/regiontestinfo/list').toPromise();
    return regionsList as RegionTestInfo[];
  }

  // Создать новую привязку к тесту для региона
  async createRegionTestInfo(regiontestinfo: RegionTestInfo): Promise<string> {
    const response = await this.http.post('/api/v1.0/player/regiontestinfo', regiontestinfo).toPromise();
    return response as string;
  }

  // Изменение привязанного к региону теста
  async updateRegionTestInfo(regiontestinfo: RegionTestInfo): Promise<any> {
    await this.http.put('/api/v1.0/player/regiontestinfo', regiontestinfo).toPromise();
  }

  // Удалить привязку региона к тесту
  async deleteRegionTestInfo(regionId: string): Promise<any> {
    await this.http.delete('/api/v1.0/player/regiontestinfo?regionId=' + regionId).toPromise();
  }
  //#endregion

  //#region Result
  // Получаем список агрегатов в текущей бд
  async getAggregatesList(): Promise<any> {
    const aggregatesList = await this.http.get('/api/v1.0/results/aggregates/list').toPromise();
    return aggregatesList as Aggregate[];
  }

  // добавить новый агрегат
  async addAggregate(object): Promise<string> {
    return await this.http.post('/api/v1.0/results/aggregates', object).toPromise();
  }

  // Получаем список объектов для агрегата
  async getObjectsByAggregateList(aggregateId: string): Promise<any> {
    const objectsList = await this.http.get('/api/v1.0/results/objects/listbyaggregate?aggregateId=' + aggregateId).toPromise();
    return objectsList as Object[];
  }

  // Добавление объекта агрегации
  async addObject(aggregateObject: Object): Promise<any> {
    const objectId = await this.http.post('/api/v1.0/results/objects', aggregateObject).toPromise();
    return objectId as string;
  }

  // Изменение объекта агрегации
  async updateObject(aggregateObject: Object): Promise<any> {
    await this.http.put('/api/v1.0/results/objects', aggregateObject).toPromise();
  }

  // Удаление объекта агрегации
  async deleteObject(objectId: string): Promise<any> {
    await this.http.delete('/api/v1.0/results/objects?objectId=' + objectId).toPromise();
  }

  // Получаем факторы для объектов агрегата
  async getFactorsList(screeningTestId: string, variantId: string, aggregateId: string): Promise<any> {
    const factorsList = await this.http
      .get('/api/v1.0/results/factors?screeningTestId=' + screeningTestId + '&variantId=' + variantId + '&aggregateId=' + aggregateId)
      .toPromise();
    return factorsList as FactorsList[];
  }

  // Создание новой записи в таблицах factor и factorMinMax
  // ATTENTION: Eсли запись с таким же набором идентификаторов уже есть, то текущая запись будет удалена и вместо нее добавлена новая
  async uploadFactorValue(uploadFactor: UploadFactor) {
    await this.http.post('/api/v1.0/results/factors/value', uploadFactor).toPromise();
  }

  // Получаем список дополнительных текстов для результатов прохождения теста
  async getAdditionalTextList(screeningTestId: string, variantId: string): Promise<any> {
    const textsList = await this.http
      .get('/api/v1.0/results/additionaltext/list' + '?screeningTestId=' + screeningTestId + '&variantId=' + variantId)
      .toPromise();
    return textsList as AdditionalText[];
  }

  // Получаем текст для результатов прохождения теста
  async getAdditionalText(screeningTestId: string, variantId: string, role: string, key: string): Promise<any> {
    const additionalText = await this.http
      .get(
        '/api/v1.0/results/additionaltext' +
          '?screeningTestId=' +
          screeningTestId +
          '&variantId=' +
          variantId +
          '&role=' +
          role +
          '&key=' +
          key,
      )
      .toPromise();
    return additionalText as AdditionalText;
  }

  // Удаление записи с доп. текстом по идентификатору
  async removeAdditionalText(additionalTextId: string) {
    await this.http.delete('/api/v1.0/results/additionaltext' + '?additionalTextId=' + additionalTextId).toPromise();
  }

  // Изменение содержимого записи с доп. текстом по идентификатору
  async updateAdditionalText(additionalText: AdditionalText) {
    await this.http.put('/api/v1.0/results/additionaltext', additionalText).toPromise();
  }

  // Добавление записи с доп. текстом
  async addAdditionalText(additionalText: AdditionalText): Promise<any> {
    const id = await this.http.post('/api/v1.0/results/additionaltext', additionalText).toPromise();
    return id as string;
  }

  // Получаем список min max
  async getMinMaxValues(screeningTestId: string, variantId: string, aggregateId: string): Promise<any> {
    const minMaxValues = await this.http
      .get(
        '/api/v1.0/results/factors/minmax?screeningTestId=' + screeningTestId + '&variantId=' + variantId + '&aggregateId=' + aggregateId,
      )
      .toPromise();
    return minMaxValues as Minmax[];
  }

  // Получаем список норм
  async getNormsValue(screeningTestId: string, variantId: string, aggregateId: string): Promise<any> {
    const norms = await this.http
      .get('/api/v1.0/results/factors/norms?screeningTestId=' + screeningTestId + '&variantId=' + variantId + '&aggregateId=' + aggregateId)
      .toPromise();
    return norms as Norm[];
  }

  public copyTest(testInfo: CopingTest): Observable<string> {
    return this.http.post(
      '/api/v1.0/player/tests/copy?sourseScreeningTestId=' +
        testInfo.sourseScreeningTestId +
        '&newScreeningTestNameInRussian=' +
        testInfo.newScreeningTestNameInRussian +
        '&newScreeningTestHrid=' +
        testInfo.newScreeningTestHrid,
    );
  }

  // Копировать факторы из другого теста
  async copyFactors(request: any) {
    await this.http.post('/api/v1.0/results/factors/copyfactors', request).toPromise();
  }

  // Загрузка документа с факторами
  async downloadFactors(screeningTestId: string, variantId: string): Promise<any> {
    let params = {
      screeningTestId: screeningTestId,
      variantId: variantId,
    };
    const response = await this.http.postFileSave('/api/v1.0/results/factors/getfactorsreport', params).toPromise();
    return response;
  }

  // Загрузка шаблона для заполнения факторов
  async downloadTemplateFactors(screeningTestId: string, variantId: string, aggregateIds: string[]): Promise<any> {
    let params = {
      screeningTestId: screeningTestId,
      variantId: variantId,
      aggregateIds: aggregateIds,
    };
    const response = await this.http.postFileSave('/api/v1.0/results/factors/getfactorsreporttemplate', params).toPromise();
    return response;
  }
  // Запись факторов из документа в базу данных
  public async uploadFactors(document: File, screeningTestId: string, variantId: string) {
    const formData: FormData = new FormData();
    formData.append(document.name, document);
    await this.http
      .postFile('/results/v1.0/factors/uploadfactors?screeningTestId=' + screeningTestId + '&variantId=' + variantId, formData)
      .toPromise();
  }

  // Запись факторов из документа в базу данных
  // async uploadFactors(document: File, screeningTestId: string, variantId: string) {
  //   let formData: FormData = new FormData();
  //   formData.append(document.name, document);
  //   await this.http
  //     .postFile('/api/v1.0/results/factors/uploadfactors?screeningTestId=' + screeningTestId + '&variantId=' + variantId, formData)
  //     .toPromise();
  // }

  // Рассчитать min max для варианта теста
  async calculateMinMax(screeningTestId: string, variantId: string) {
    await this.http
      .post('/api/v1.0/results/factors/calculateminmax?screeningTestId=' + screeningTestId + '&variantId=' + variantId)
      .toPromise();
  }

  // Пересчитать нормы по агрегату
  async rebuildNorms(dto: RebuildNorms) {
    await this.http.post('/api/v1.0/results/calculator/norms', dto).toPromise();
  }

  // Добавить привязку объекта расчета к территориальной единице
  async addTerritoryObject(dto: TerritoryObject): Promise<string> {
    const id = await this.http.post('/api/v1.0/results/territory', dto).toPromise();
    return id as string;
  }

  async updateTerritoryObject(dto: TerritoryObject): Promise<any> {
    await this.http.put('/api/v1.0/results/territory', dto).toPromise();
  }

  // Запросить все territoyObjects по objectId
  async getTerritoryObjects(objectId: string, objectType: ObjectTypes): Promise<TerritoryObject[]> {
    const territoryObjects = await this.http
      .get('/api/v1.0/results/territory/getbyobject?objectId=' + objectId + '&objectType=' + objectType)
      .toPromise();
    return territoryObjects as TerritoryObject[];
  }

  async deleteTerritoryObject(id: string): Promise<any> {
    await this.http.delete('/api/v1.0/results/territory?id=' + id).toPromise();
  }
  //#endregion

  //#region Utils
  guid() {
    function s4() {
      return Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1);
    }

    return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
  }

  async uploadImage(image: FileList, id: string): Promise<any> {
    let formData: FormData = new FormData();
    formData.append(image[0].name, image[0]);
    return await this.http.postImage('/api/v1.0/utils/upload/' + id, formData).toPromise();
  }
  //#endregion

  // Перенести тест на продакшн
  public async postTestExport(screeningTestId: string, variantTestId?: string): Promise<any> {
    return await this.http
      .post('/api/v1.0/player/tests/export', { screeningTestId: screeningTestId, variantId: variantTestId })
      .toPromise();
  }
}
