import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './router.config';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppComponent } from './app.component';

import { HttpService } from './services/http.service';
import { GatewayService } from './services/gateway.service';
import { MaterialModule } from './material/material.module';
import { CommonModule } from '@angular/common';
import { ProfilumComponentsModule } from '../../../../libs/profilum-components/src/profilum-components.module';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    HttpClientModule,
    BrowserAnimationsModule,
    MaterialModule,
    ProfilumComponentsModule,
    CommonModule,
    AppRoutingModule,
  ],
  providers: [HttpService, GatewayService],
  bootstrap: [AppComponent],
})
export class AppModule {}
