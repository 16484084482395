import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class HttpService {
  private headers = new HttpHeaders({ 'Content-Type': 'application/json;charset=utf-8' });
  private apiUrl = '/api';
  constructor(private http: HttpClient) {}

  get(path, valParams?): Observable<any> {
    let params = {
      params: valParams || null,
    };
    return this.http.get(this.apiUrl + path, params);
  }

  post(path, data?): Observable<any> {
    const headers = { headers: this.headers };
    return this.http.post(this.apiUrl + path, JSON.stringify(data || {}), headers);
  }

  put(path, data?): Observable<any> {
    const headers = { headers: this.headers };
    return this.http.put(this.apiUrl + path, JSON.stringify(data || {}), headers);
  }

  delete(path): Observable<any> {
    return this.http.delete(this.apiUrl + path);
  }

  postFile(path, data?) {
    return this.http.post(this.apiUrl + path, data);
  }

  postFileSave(path, data?) {
    let header = new HttpHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json-patch+json',
    });
    return this.http.post(this.apiUrl + path, JSON.stringify(data || {}), {
      headers: header,
      observe: 'response',
      responseType: 'blob',
    });
  }

  postImage(path, data?) {
    let headers = new HttpHeaders();
    headers.append('Accept', 'application/json');
    const options = { headers: headers };
    return this.http.post(this.apiUrl + path, data, options);
  }
}
