import { Injectable } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../auth/auth.service';

@Injectable({
  providedIn: 'root',
})
export class LoginGuard  {
  constructor(private authService: AuthService, private router: Router) {}

  canActivate(): Observable<boolean> | Promise<boolean> | boolean {
    return this.checkAuth();
  }

  canActivateChild(): Observable<boolean> | any | Promise<boolean> | boolean | UrlTree {
    return this.canActivate();
  }

  checkAuth(): Observable<boolean> | Promise<boolean> | boolean {
    this.authService.checkCookie().subscribe((response) => {
      if (response.status !== 'Success') {
        localStorage.clear();
        this.router.navigate(['/login']);
        return false;
      }
    });

    const authData: any = this.authService.getAuthData();
    if (!authData) {
      localStorage.clear();
      this.router.navigate(['/login']);
      return false;
    } else {
      return true;
    }
  }
}
