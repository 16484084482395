import { ModuleWithProviders } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginGuard } from './guards/login.guard';

const AppRoutes: Routes = [
  {
    path: '',
    loadChildren: () => import('src/app/components/components.module').then(m => m.ComponentsModule),
    canActivate: [LoginGuard],
  },
  {
    path: 'login',
    loadChildren: () => import('src/app/auth/auth.module').then(m => m.AuthModule),
  },
  { path: '**', redirectTo: '/' },
];

export const AppRoutingModule: ModuleWithProviders<RouterModule> = RouterModule.forRoot(AppRoutes, {});
